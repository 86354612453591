.login-cont {
    background-color: #e7e7e7;
    height: 100vh;
}

.login-box {
    height: 80vh;
}

.login-form {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.login-form .btn-hyundai {
    background-color: #002c5f;
    border: 0;
}

.login-form .btn-hyundai:hover {
    background-color: #03356e;
    border: 0;
}

.login-recover {
    text-align: center;
    display: block;
}

@media (max-width: 575.98px) {
    .login-form {
        box-shadow: none;
    }
}