.archivo {
    margin-left: 5px;
}

.icono {
    margin-left: 5px;
    margin-bottom: 5px;
}
.modal-documents-icono{
    float: right;
}
.form-holder{
    width: 200px;
}
.icon-file{
    cursor: pointer;
    display: flex;

}
.button-carga{
    margin: 30px 10px;
    width: 100px;
    display: inline-block;

}