.table-container {
    overflow: auto;
    max-height: 160px;
    border: 1px solid gray;
  }
  
  th {
    position: sticky;
    top: 0;
    background: white;
  }


  .title-create {
    text-align: left;
    font-weight: bold;
    color: black;
  }