.titulo-seleccione {
  color: #ffffff;
  background-color: #002c5e;
  font-family: HyundaiSansHead-Medium;
  font-size: 24px;
}

.listado-empresa-cont {
  background-color: #e7e7e7;
  min-height: 100vh;
}

.emp-img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition-duration: 0.1s;
  cursor: pointer;
}

.emp-img:hover {
  transform: scale(1.03);
}
