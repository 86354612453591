.archivo {
    margin-left: 5px;
}

.icono {
    margin-left: 5px;
    margin-bottom: 5px;
}
.modal-documents-icono{
    float: right;
}
.form-holder{
    width: 100% !important;
}
.icon-file{
    cursor: pointer;
    display: flex;
   
    
}
.totales-estados{
    background-color: white !important;
    border: #000;
    margin-right: 30px;

}
.totales-estados_abierto{
    background-color: yellow ;
    color: black;
    border: 2px  !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_abierto-de{
    padding-left: 10px;
    text-align: right;
    
}
.totales-estados_pendiente{
    background-color:  orange;
    color: black;
    border: 2px !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_pendiente-de{
    padding-left: 10px;
    text-align: right;
    
}
.totales-estados_validado{
    background-color: skyblue ;
    color: black;
    border: 2px !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_validado-de{
   
   padding-left: 10px;
    text-align: right;
}
.totales-estados_preAprobado{
    background-color: blue ;
    color: white;
    border: 2px !important;
    margin: 10px;
    width: 135px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_preAprobado-de{
    padding-left: 10px;
    text-align: left;   
}
.totales-estados_aprobado{
    background-color: var(--green) ;
    color: black;
    border: 2px  !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_aprobado-de{
    padding-left: 10px;
    text-align: right;
}

.totales-estados_aplicado{
    background-color: plum ;
    color: black;
    border: 2px  !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_aplicado-de{
    padding-left: 10px;
    text-align: right;
}
.totales-estados_rechazado{
    background-color: red ;
    color: black;
    border: 2px  !important;
    margin: 10px;
    width: 130px;
    text-align: left;
    display: flex;
    overflow: hidden;
}
.totales-estados_rechazado-de{
    padding-left: 10px;
    text-align: right;
}
.total-bonos{
    margin: 10px;
    margin-left: 10px;
    
    color: #000;
    width: 10%;
}
.total-bonos-td{
   
    text-align: center;
}
.table-bootstrap{
    position: sticky;
    position: -webkit-sticky; 
}
.filter-box {
    
    display: flex;
    flex-direction: column;
}

    
@media screen and (max-width: 1400px) {
    .filter-box{
        
        width: 100%;
        max-width: 1010px;
    }
}  
@media screen and (max-width: 1920px)  {
    .filter-box{
        width: 100%;
    }
}
  